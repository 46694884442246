export default {
    private:
        '우리 헌법은 국민의 기본권인 사생활의 비밀과 자유 및 통신의 비밀을 보장하고 있으므로 도/감청 등에 의한 개인정보 및 사생활의 은밀한 탐지는 원칙적으로 불법입니다. 그러나 우리 사회에 횡행하는 불법 도청, 통신상의 정보 유출로 인하여 심각한 인권 침해가 나타나고 있고 국민 개개인의 자유로운 생활형성이 위협받고 있습니다.\n' +
        '\n' +
        '이러한 기본권 침해의 소지를 원천적으로 제거함으로써 KoROM-Database 회원의 프라이버시를 철저히 보호하여 정보화 사회에서의 통신의 자유를 보장하고자 아래와 같이 개인정보 보호정책을 명시합니다. 개인정보 보호정책은 정부의 법률 및 지침의 변경과 KoROM-Database의 정책 변화에 따라 변경될 수 있습니다. 회원님께서는 사이트 방문 시에 수시로 확인하시기 바랍니다. \n' +
        '\n' +
        '<b>1. 개인정보의 수집목적 및 이용</b>\n'+
        '회원 개인의 정보를 수집하는 목적은 KoROM-Database 사이트(이하 “사이트”라 합니다)를 통하여 회원님께 최적의 맞춤화된 서비스를 제공해드리기 위한 것입니다. 사이트는 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.\n' +
        '- 회원가입 의사 확인, 회원에 대한 서비스 제공에 따른 본인 식별 및 인증, 회원 자격 유지 관리, 회원별 평가 결과 제공 등\n' +
        '\n' +
        '<b>2. 수집하는 개인정보 항목 및 수집방법</b>\n'+
        '사이트는 최초 회원가입을 하실 때 서비스 제공을 위해 가장 필수적인 개인정보를 받고 있습니다. 회원가입 시에 받는 정보는 회원님의 이름, 성별, 생년월일 등입니다.\n' +
        '이 이외에 특정 서비스를 제공하기 위하여 추가적인 정보제공을 요청하고 있습니다.\n' +
        '실명확인을 위한 주민등록번호, 휴대폰번호, 이메일, 기타 서비스제공에 필요한 추가정보의 기재를 요청하게 됩니다. 또한 설문조사나 이벤트 시에 집단적인 통계분석을 위해서나 경품발송을 위한 목적으로도 개인정보 기재를 요청할 수 있습니다. 이때에도 기입하신 정보는 해당서비스 제공이나 회원님께 사전에 밝힌 목적 이외의 다른 어떠한 목적으로도 사용되지 않음을 알려드립니다. \n' +
        '\n' +
        '<b>3. 개인정보의 보유 및 폐기</b>\n'+
        '귀하가 사이트의 회원으로서 사이트가 제공하는 서비스를 받는 동안 회원님의 개인정보는 사이트에서 계속 보유하며 서비스 제공을 위해 이용하게 됩니다. 다만 사이트의 회원이 아래의 \'7. 자신의 개인정보 열람, 정정 및 삭제\'에서 설명한 절차에 따라 ID를 삭제하거나 가입해지를 요청한 경우와 회원님께 사전에 알려드린 개인정보를 제공받은 목적이 달성된 경우에 수집된 개인의 정보는 재생할 수 없는 방법에 의하여 하드디스크에서 완전히 삭제되며 어떠한 용도로도 열람 또는 이용할 수 없도록 처리됩니다. \n' +
        '\n' +
        '<b>4. 개인정보의 제공 및 공유</b>\n'+
        '원칙적으로 회원님의 개인정보를 타인 또는 타기업·기관에 공개하지 않습니다. 다만 회원님이 공개에 동의한 경우 또는 사이트의 서비스 이용약관을 위배하는 사람이나 서비스를 이용하여 타인에게 법적인 피해를 주거나 미풍양속을 해치는 행위를 한 사람 등에게 법적인 조치를 취하기 위하여 개인정보를 공개해야 한다고 판단되는 충분한 근거가 있는 경우는 예외로 합니다.\n' +
        '사이트는 보다 나은 서비스 제공을 위해 회원님의 개인정보를 비즈니스 파트너와 공유할 수 있습니다. 이 경우에도 정보수집 또는 정보제공 이전에 회원님께 비즈니스 파트너가 누구인지, 어떤 정보가 왜 필요한지, 그리고 언제까지 어떻게 보호/관리되는지 알려드리고 동의를 구하는 절차를 거치게 되며, 회원님께서 동의하지 않는 경우에는 추가적인 정보를 수집하거나 비즈니스 파트너와 공유하지 않습니다.\n' +
        '성별, 연령별 기타 특정 조건의 집단에 대한 광고 게재 및 발송 시(예, 20대 여자)에도 회원님 개인의 정보는 광고를 의뢰한 개인이나 단체에 제공되지 않습니다. 기타 통계처리, 학술연구, 시장조사를 위하여 필요한 경우에는 특정한 개인을 식별할 수 없는 형태로만 정보가 제공됩니다. \n' +
        '\n' +
        '<b>5. 쿠키(cookie)의 운용 및 활용</b>\n'+
        '회원님 개개인에게 개인화되고(personalized) 맞춤화된(customized) 서비스를 제공하기 위해서 사이트는 회원님의 정보를 저장하고 수시로 불러오는 \'쿠키(cookie)\'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 사용자의 브라우저에게 보내는 조그마한 데이터 꾸러미로 회원님 컴퓨터의 하드디스크에 저장됩니다.\n' +
        '회원님께서 사이트에 접속한 후 로그인(log-in)하여 개인화된 서비스나 맞춤화된 서비스를 이용하시기 위해서는 쿠키를 허용하여야 합니다. 사이트는 회원님께 적합하고 보다 유용한 서비스를 제공하기 위해서 쿠키를 이용하여 회원님의 아이디에 대한 정보를 찾아냅니다.\n' +
        '사이트에 접속하는 이용자의 브라우저에 고유한 쿠키를 부여함으로써 회원 및 비회원의 사이트 이용빈도나 전체 이용자 수 등과 같은 이용자 규모를 파악하는 데도 이용됩니다.\n' +
        '쿠키를 이용하여 회원님께서 방문한 사이트의 서비스 방문 및 이용형태를 파악함으로써 더 유용하고 이용하기 편리한 서비스를 만들어 제공할 수 있게 됩니다.\n' +
        '회원님은 쿠키에 대한 선택권을 가지고 있습니다. 회원님의 웹브라우저에서 옵션을 선택함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단, 쿠키의 저장을 거부하실 경우 로그인이 필요한 KoROM-Database의 모든 서비스는 이용하실 수 없게 됩니다. \n' +
        '\n' +
        '<b>6. 개인정보보호를 위한 기술적/제도적 관리</b>\n'+
        '회원님의 개인정보는 비밀번호에 의해 보호되고 있습니다. 회원님 계정의 비밀번호는 오직 본인만이 알 수 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 따라서 회원님의 비밀번호는 누구에게도 알려주면 안됩니다. 또한, 작업을 마치신 후에는 로그아웃(log-out)하시고 웹브라우저를 종료하는 것이 바람직합니다. 특히 다른 사람과 컴퓨터를 공유하여 사용하거나 공공장소에서 이용한 경우 개인정보가 다른 사람에게 알려지는 것을 막기 위해서 이와 같은 절차가 더욱 필요하다고 하겠습니다.\n' +
        '사이트는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트 되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 훼손되는 것을 방지하고 있습니다.\n' +
        '해킹 등에 의해 회원의 개인정보가 유출되는 것을 막기 위해 현재 외부로부터의 침입을 차단하는 장치를 이용하여 외부로부터의 공격, 해킹 등을 막고 있으며, 각 서버마다 침입탐지시스템을 설치하여 24시간 침입을 감시하고 있습니다. 기타 회원 개인정보의 훼손에 대비해서 시스템과 데이터를 백업하여 만약의 사태에 대비하고 있습니다.\n' +
        '사이트는 개인정보 취급 직원을 최소한으로 제한하고 담당직원에 대한 수시 교육을 통하여 본 정책의 준수를 강조하고 있으며, 감사위원회의 감사를 통하여 본 정책의 이행사항 및 담당직원의 준수여부를 확인하여 문제가 발견될 경우 바로 시정조치하고 있습니다. \n' +
        '\n' +
        '<b>7. 자신의 개인정보 열람, 정정 및 삭제</b>\n'+
        '회원님은 언제든지 등록되어있는 회원님의 개인정보를 열람하거나 정정하실 수 있으며, 아이디(ID) 삭제를 요청하실 수 있습니다.\n' +
        '개인정보와 관련하여 불만이나 의견이 있으신 분은 개인정보 관리담당자에게 메일(veritaspt@gmail.com)로 의견을 주시면 접수 즉시 조치하고 처리결과를 통보해 드리겠습니다. \n' +
        '\n' +
        '개인정보 관리담당자\n' +
        '이 름 : 김수진\n' +
        'TEL : 063-220-2226\n' +
        '이메일 : veritaspt@gmail.com\n' +
        '이상의 변경된 개인정보보호정책은 <2022.03.01>부터 시행합니다.\n',

    service: '<b><h5 class="sub-title">제 1 장 총 칙</h5></b>\n' +
        '\n' +
        '<b>제 1 조 (목적)</b>\n'+
        '\n' +
        '이 약관은 “KoROM-Database”(이하 "사이트"라 합니다)에서 제공하는 인터넷 서비스(이하 “서비스”라 합니다)의 이용조건과 운영에 관한 기본적인 사항 규정을 목적으로 합니다.'+
        '\n' +
        '\n' +
        '<b>제 2 조 (약관의 효력 및 변경)</b>\n'+
        '\n' +
        '① 이 약관은 서비스 화면이나 기타의 방법으로 이용고객에게 공지함으로써 효력을 발생합니다.\n' +
        '② 사이트는 이 약관의 내용을 변경할 수 있으며, 변경된 약관은 제1항과 같은 방법으로 공지 또는 통지함으로써 효력을 발생합니다.' +
        '\n' +
        '\n' +
        '<b>제 3 조 (용어의 정의)</b>\n'+
        '\n' +
        '이 약관에서 사용하는 용어의 정의는 다음과 같습니다.\n'+
        '① 회원 : 사이트의 약관에 동의하고 개인정보를 제공하여 회원등록을 한 자로서, 사이트와의 이용계약을 체결하거나 이용자 아이디(ID)를 부여받은 사이트를 이용하는 이용자를 말합니다.\n' +
        '② 신청자 : 회원가입을 신청하는 개인 또는 단체를 말합니다.\n' +
        '③ 아이디(ID) : 회원의 식별과 서비스 이용을 위하여 회원이 정하고 사이트가 승인하는 문자와 숫자의 조합을 말합니다.\n' +
        '④ 비밀번호 : 회원이 부여받은 아이디(ID)와 일치된 회원임을 확인하고, 회원 자신의 비밀을 보호하기 위하여 회원이 정한 문자와 숫자의 조합을 말합니다.\n'+
        '⑤ 해지 : 사이트 또는 회원이 서비스 이용계약을 취소하는 것을 말합니다.\n' +
        '\n' +
        '\n' +
        '<b><h5 class="sub-title">제 2 장 서비스 이용계약</h5></b>\n' +
        '\n' +
        '<b>제 4 조 (이용계약의 성립)</b>\n'+
        '\n' +
        '① 이용약관 하단의 동의 버튼을 누르면 이 약관에 동의하는 것으로 간주됩니다.\n' +
        '② 이용계약은 서비스 이용희망자의 이용약관 동의 후 이용 신청에 대하여 사이트가 승낙함으로써 성립합니다.\n'+
        '\n'+
        '<b>제 5 조 (이용신청)</b>\n' +
        '① 신청자가 본 서비스를 이용하기 위해서는 사이트 소정의 가입신청 양식에서 요구하는 이용자 정보(이용자 ID, 비밀번호 등)를 기록하여 제출해야 합니다.\n'+
        '② 가입신청 양식에 기재하는 모든 이용자 정보는 모두 실제 데이터인 것으로 간주됩니다. 실명이나 실제 정보를 입력하지 않은 사용자는 법적인 보호를 받을 수 없으며, 서비스의 제한을 받을 수 있습니다.\n'+
        '\n'+
        '<b>제 6 조 (이용신청의 승낙)</b>\n'+
        '① 사이트는 신청자에 대하여 제2항, 제3항의 경우를 예외로 하여 서비스 이용신청을 승낙합니다.\n'+
        '② 사이트는 다음에 해당하는 경우에 그 신청에 대한 승낙 제한사유가 해소될 때까지 승낙을 유보할 수 있습니다.\n'+
        '가. 서비스 관련 설비에 여유가 없는 경우\n'+
        '나. 기술상 지장이 있는 경우\n'+
        '다. 기타 사이트가 필요하다고 인정되는 경우\n'+
        '③ 사이트는 신청자가 다음에 해당하는 경우에는 승낙을 거부할 수 있습니다.\n'+
        '가. 다른 개인(사이트)의 명의를 사용하여 신청한 경우\n'+
        '나. 이용자 정보를 허위로 기재하여 신청한 경우\n'+
        '다. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우\n'+
        '라. 기타 사이트 소정의 이용신청요건을 충족하지 못하는 경우\n'+
        '\n'+
        '<b>제 7 조 (이용자정보의 변경)</b>\n'+
        '회원은 이용 신청 시 기재했던 회원 정보가 변경되었을 경우, 온라인으로 수정하여야 하며 변경하지 않음으로 인하여 발생하는 모든 문제의 책임은 회원에게 있습니다.\n'+
        '\n'+
        '\n'+
        '<b><h5 class="sub-title">제 3 장 계약 당사자의 의무</h5></b>\n' +
        '\n'+
        '<b>제 8 조 (사이트의 의무)</b>\n'+
        '① 사이트는 서비스 제공과 관련하여 취득한 회원의 개인정보를 회원의 동의없이 타인에게 누설, 공개 또는 배포할 수 없으며, 서비스관련 업무 이외의 상업적 목적으로 사용할 수 없습니다. 단, 다음 각 호의 1에 해당하는 경우는 예외입니다.\n'+
        '가. 전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우\n'+
        '나. 범죄에 대한 수사상의 목적이 있거나 정보통신윤리 위원회의 요청이 있는 경우\n'+
        '다. 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우\n'+
        '② 사이트는 이 약관에서 정한 바에 따라 지속적, 안정적으로 서비스를 제공할 의무가 있습니다.\n'+
        '\n'+
        '<b>제 9 조 (회원의 의무)</b>\n'+
        '① 회원은 서비스 이용 시 다음 각 호의 행위를 하지 않아야 합니다.\n'+
        '가. 다른 회원의 ID를 부정하게 사용하는 행위\n'+
        '나. 서비스에서 얻은 정보를 사이트의 사전승낙 없이 회원의 이용 이외의 목적으로 복제하거나 이를 변경, 출판 및 방송 등에 사용하거나 타인에게 제공하는 행위\n'+
        '다. 사이트의 저작권, 타인의 저작권 등 기타 권리를 침해하는 행위\n'+
        '라. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 타인에게 유포하는 행위\n'+
        '마. 범죄와 결부된다고 객관적으로 판단되는 행위\n'+
        '바. 기타 관계법령에 위배되는 행위\n'+
        '② 회원은 관계법령, 이 약관에서 규정하는 사항, 서비스 이용 안내 및 주의 사항을 준수하여야 합니다.\n'+
        '③ 회원은 내용별로 사이트가 서비스 공지사항에 게시하거나 별도로 공지한 이용 제한 사항을 준수하여야 합니다.\n'+
        '\n'+
        '\n'+
        '<b><h5 class="sub-title">제 4 장 서비스 제공 및 이용</h5></b>\n' +
        '\n'+
        '<b>제 10 조 (회원 아이디(ID)와 비밀번호 관리에 대한 회원의 의무)</b>\n'+
        '① 아이디(ID)와 비밀번호에 대한 모든 관리는 회원에게 책임이 있습니다. 회원에게 부여된 아이디(ID)와 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 전적인 책임은 회원에게 있습니다.\n'+
        '② 자신의 아이디(ID)가 부정하게 사용된 경우 또는 기타 보안 위반에 대하여, 회원은 반드시 사이트에 그 사실을 통보해야 합니다.\n'+
        '\n'+
        '<b>제 11 조 (서비스 제한 및 정지)</b>\n'+
        '① 사이트는 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간통신 사업자가 전기통신서비스를 중지하는 등 기타 불가항력적 사유가 있는 경우에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.\n'+
        '② 사이트는 제1항의 규정에 의하여 서비스의 이용을 제한하거나 정지할 때에는 그 사유 및 제한기간 등을 지체없이 회원에게 알려야 합니다.\n'+
        '\n'+
        '\n'+
        '<b><h5 class="sub-title">제5장 계약사항의 변경, 해지</h5></b>\n' +
        '\n'+
        '<b>제 12 조 (정보의 변경)</b>\n'+
        '회원이 주소, 비밀번호 등 고객정보를 변경하고자 하는 경우에는 홈페이지의 회원정보 변경 서비스를 이용하여 변경할 수 있습니다.\n'+
        '\n'+
        '<b>제 13 조 (계약사항의 해지)</b>\n'+
        '회원은 서비스 이용계약을 해지할 수 있으며, 해지할 경우에는 본인이 직접 서비스를 통하거나 전화 또는 온라인 등으로 사이트에 해지신청을 하여야 합니다. 사이트는 해지신청이 접수된 당일부터 해당 회원의 서비스 이용을 제한합니다. 사이트는 회원이 다음 각 항의 1에 해당하여 이용계약을 해지하고자 할 경우에는 해지조치 7일전까지 그 뜻을 이용고객에게 통지하여 소명할 기회를 주어야 합니다.\n'+
        '① 이용고객이 이용제한 규정을 위반하거나 그 이용제한 기간 내에 제한 사유를 해소하지 않는 경우\n'+
        '② 정보통신윤리위원회가 이용해지를 요구한 경우\n'+
        '③ 이용고객이 정당한 사유 없이 의견진술에 응하지 아니한 경우\n'+
        '④ 타인 명의로 신청을 하였거나 신청서 내용의 허위 기재 또는 허위서류를 첨부하여 이용계약을 체결한 경우 사이트는 상기 규정에 의하여 해지된 이용고객에 대해서는 별도로 정한 기간동안 가입을 제한할 수 있습니다.\n'+
        '\n'+
        '\n'+
        '<b><h5 class="sub-title">제6장 손해배상</h5></b>\n' +
        '\n'+
        '<b>제 14 조 (면책조항)</b>\n'+
        '① 사이트는 회원이 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스 자료에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다.\n'+
        '② 사이트는 회원의 귀책사유나 제3자의 고의로 인하여 서비스에 장애가 발생하거나 회원의 데이터가 훼손된 경우에 책임이 면제됩니다.\n'+
        '③ 사이트는 회원이 게시 또는 전송한 자료의 내용에 대해서는 책임이 면제됩니다.\n'+
        '④ 상표권이 있는 도메인의 경우, 이로 인해 발생할 수도 있는 손해나 배상에 대한 책임은 구매한 회원 당사자에게 있으며, 사이트는 이에 대한 일체의 책임을 지지 않습니다.\n'+
        '\n'+
        '<b>제 15 조 (관할법원)</b>\n'+
        '서비스와 관련하여 사이트와 회원간에 분쟁이 발생할 경우 사이트의 본사 소재지를 관할하는 법원을 관할법원으로 합니다.\n'+
        '\n'+
        '[부칙]\n'+
        '\n'+
        '이 약관은 <2022.03.01>부터 시행합니다\n'

};
